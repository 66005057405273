import React, { useEffect } from 'react';
import { Formik, Form as FForm, useFormikContext, ErrorMessage } from 'formik';
import {
    Button,
    FormInput,
    FormSelect,
    FormInputList,
    FormSelectList,
    Text,
    FormFileInput,
    RadioButton,
    Checkbox
} from './';
import * as Yup from 'yup';
import { FormImageInput } from './FormImageInput';
import { ObjectShape } from 'yup';
import { DuplicateFields } from './DuplicateFields';
import { useIntl } from 'react-intl';


export const FormikOnChangeComponent: ({onChange}: { onChange: Function }) => null = ({onChange}) => {
    const { values } = useFormikContext();
    useEffect(() => {
        onChange(values);
    }, [values]);

    return null;
};


type Props = {
    enableReinitialize?: boolean,
    noSubmitBtn?: boolean,
    submitBtnSmall?: boolean,
    submitBtnFullWidth?: boolean,
    duplicateAllFields?: boolean,
    showGeneralErrorOnSubmit?: boolean,
    formButton?: string,
    isFormDisabled?: boolean,
    initValues: object,
    validationSchema: ObjectShape,
    setValidationSchema?: Function,
    onSubmit: (values: any, actions: any) => void,
    onChange?: Function,
    cancelBtn?: (e: any) => void,
    className?: string,
    submitBtnClass?: string,
    errors?: string,
    cancelBtnLabel?: string,
    submitBtnLabel?: string,
    confirmationalCheckbox?: any,
    submitBtnIcon?: string,
    duplicateAllFieldsLabel?: string,
    fields: any[],
    setFields?: Function,
    fieldsWrapperClassName?: string,
    resetInitValuesOnCancel?: boolean
}


export const Form: (props: Props) => JSX.Element =
    ({
         enableReinitialize,
         noSubmitBtn,
         formButton,
         submitBtnSmall,
         submitBtnFullWidth,
         showGeneralErrorOnSubmit,
         initValues,
         validationSchema,
         setValidationSchema,
         onSubmit,
         errors,
         isFormDisabled,
         onChange,
         cancelBtn,
         duplicateAllFieldsLabel,
         className,
         submitBtnClass,
         cancelBtnLabel,
         submitBtnLabel,
         submitBtnIcon,
         confirmationalCheckbox,
         fields,
         duplicateAllFields,
         setFields,
         fieldsWrapperClassName,
         resetInitValuesOnCancel,
    }) => {

    const intl = useIntl();

    const getInlineFields = (field: any, setFieldValue: Function, idx: number, values: any) => {
        if (field.dependsOn !== undefined) {
            if ((field.dependsOn.showWhen !== undefined &&
                    (values as any)[field.dependsOn.select] !== field.dependsOn.showWhen) ||
                (field.dependsOn.showWhenOneOf !== undefined &&
                    field.dependsOn.showWhenOneOf.indexOf((values as any)[field.dependsOn.select]) === -1)) {
                return null;
            }
        }

        return <>
            <div className={field.fields.length > 1 ? "columns-2" : ""} key={idx}>
                {field.fields.map((f: any, fIdx: any) => {
                    switch (f.type) {
                        case 'inline_fields':
                            return getInlineFields(f, setFieldValue, fIdx, values);
                        case 'select':
                            return <div className="flex-1"><FormSelect key={fIdx} {...f} /></div>;
                        case 'textarea':
                            return <div className="flex-1"><FormInput key={fIdx} tag="textarea" {...f} /></div>;
                        case 'password':
                            return <div className="flex-1"><FormInput key={fIdx} type="password" {...f} onChange={setFieldValue} /></div>;
                        case 'input_list':
                            return <div className="flex-1"><FormInputList key={fIdx} {...f} onChange={setFieldValue} /></div>;
                        case 'select_list':
                            return <div className="flex-1"><FormSelectList key={fIdx} {...f} onChange={setFieldValue} /></div>;
                        default:
                            return <div className="flex-1">
                                <FormInput key={fIdx} {...f} onChange={setFieldValue} />
                            </div>
                    }
                })}
            </div>
        </>
    }

    return(
        <Formik
            enableReinitialize={enableReinitialize ? enableReinitialize : false}
            initialValues={initValues}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, dirty, isValid, setFieldValue, values, submitForm, touched, setValues }) => (
                <FForm autoComplete="off" className={'space-y-6 ' + (className || '')}>
                    {onChange &&
                        <FormikOnChangeComponent onChange={onChange} />
                    }
                    <div className={'space-y-6 ' + (fieldsWrapperClassName || '')}>
                        {fields.map((field, idx) => {
                                switch (field.type) {
                                    case 'select':
                                        return <FormSelect key={idx} {...field} />;
                                    case 'textarea':
                                        return <FormInput key={idx} tag="textarea" {...field} />;
                                    case 'password':
                                        return <FormInput key={idx} type="password" {...field} onChange={setFieldValue} />;
                                    case 'input_list':
                                        return <FormInputList key={idx} {...field} onChange={setFieldValue} />;
                                    case 'select_list':
                                        return <FormSelectList key={idx} {...field} onChange={setFieldValue} />;
                                    case 'file':
                                        return <FormFileInput key={idx} {...field} onChange={setFieldValue} />;
                                    case 'image':
                                        return <FormImageInput key={idx} {...field} onChange={setFieldValue} />;
                                    case 'radio':
                                        return <RadioButton key={idx}  name={field.name} label={field.label}
                                            className={`mt-3 ${field.column !== undefined ? 'flex' : ''}`} column={field.column} checked={(values as any)[field.name]} items={field.fields}
                                            onChange={(idx: number) => {
                                                setFieldValue(field.name, idx, true);
                                            }} />
                                    case 'radio_with_label':
                                        return <div key={idx} className="flex items-center gap-28 px-5 py-4 relative border border-solid border-super-light-grey">
                                            <div className="flex flex-col items-start gap-1 relative">
                                                <Text left label={field.title} className="text-l font-semibold w-fit whitespace-nowrap"/>
                                                <Text left label={field.subtitle} className="text-sm text-bk-grey-300" />
                                            </div>
                                            <RadioButton className="mt-3 flex" column={true} name={field.name} label={field.label}
                                                         checked={(values as any)[field.name]} items={field.fields}
                                                         onChange={(idx: number) => {
                                                             setFieldValue(field.name, idx, true);
                                                         }}/>
                                        </div>
                                    case 'array':
                                        return <div key={idx} className="flex space-x-5">
                                            {(initValues as any)[field.name].map((value: string, valueIdx: any) => (
                                                <div className="flex-1">
                                                    <FormInput
                                                        key={valueIdx}
                                                        name={field.name + valueIdx}
                                                        label={field.label}
                                                        onChange={setFieldValue} />
                                                </div>
                                            ))}
                                        </div>
                                    case 'title':
                                        return <div key={idx}>
                                            {!field.withoutLine && <hr className="mt-10 mb-10" />}
                                            <Text left label={field.title} className="text-l font-bold"/>
                                            <Text left label={field.subtitle} className="text-sm text-bk-grey-300 mb-5" />
                                        </div>
                                    case 'text_without_input':
                                        return <Text key={idx} left label={field.text} className="text-sm font-medium pb-5"/>
                                    case 'separator':
                                        return <div key={idx}>
                                            <hr className="mt-10 mb-10" />
                                        </div>
                                    case 'inline_fields':
                                        return getInlineFields(field, setFieldValue, idx, values);
                                    case 'inline_fields_with_label':
                                        return <div key={idx}>
                                            <div className="flex">
                                                <div className="flex flex-1 items-start gap-1 relative">
                                                    <Text label={field.label} left className="text-sm align-middle font-semibold my-auto w-fit whitespace-nowrap"/>
                                                </div>
                                                <div className="flex-1 ml-5">
                                                    {getInlineFields(field, setFieldValue, idx, values)}
                                                </div>
                                            </div>
                                            <hr className="mt-10 mb-10" />
                                        </div>
                                    case 'checkbox':
                                        return <div>
                                            <div className="flex space-x-2">
                                                <Checkbox
                                                    checked={field.checked}
                                                    onChange={(checked) => setFieldValue(field.name, checked)}
                                                />
                                                <Text left sm>
                                                    <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: field.label})}} />
                                                </Text>
                                            </div>
                                            <Text sm className="text-red-500 sm:text-sm">
                                                <ErrorMessage name={field.name} />
                                            </Text>
                                        </div>
                                    case 'duplicate_fields':
                                        const duplicateFieldsHandleSubmit = (duplicateValues: any, duplicateIdx: number | null, duplicateCallback: Function) => {
                                            const duplicateFields = (values as any)[field.name];

                                            if (duplicateIdx === null) { //create
                                                duplicateFields.push(duplicateValues);
                                            } else { //edit
                                                duplicateFields[duplicateIdx] = duplicateValues;
                                            }

                                            setFieldValue(field.name, duplicateFields, true);

                                            if (duplicateCallback !== undefined) {
                                                duplicateCallback();
                                            }
                                        };

                                        return <div className="flex-1">
                                            <DuplicateFields
                                                items={(values as any)[field.name]}
                                                inputFields={field.inputFields}
                                                validationSchema={field.validationSchema}
                                                displayFields={field.displayFields}
                                                initValues={field.initValues}
                                                handleSubmit={duplicateFieldsHandleSubmit}
                                                noSubmitBtn={true}
                                                submitButtonLabel={field.submitButtonLabel}
                                                title={field.title}
                                            />
                                        </div>;
                                    default:
                                        if (field.dependsOn !== undefined) {
                                            if ((field.dependsOn.showWhen !== undefined &&
                                                (values as any)[field.dependsOn.select] !== field.dependsOn.showWhen) ||
                                                (field.dependsOn.showWhenOneOf !== undefined &&
                                                    field.dependsOn.showWhenOneOf.indexOf((values as any)[field.dependsOn.select]) === -1)) {
                                                return null;
                                            }
                                        }

                                        return <div key={idx}>
                                            <FormInput {...field} onChange={setFieldValue} />
                                        </div>
                                }
                            }
                        )}
                    </div>
                    {formButton && touched && dirty &&
                        <Text error left label="bk.form.press_button" />
                    }
                    {formButton &&
                        <button className="w-full" onClick={submitForm} type="button">
                            <div className={`${touched && dirty ? 'border-bk-red' : 'border-bk-black'} relative w-full h-14 border border-solid border-bk-black cursor-pointer text-center flex`}>
                                <div className="text-center whitespace-nowrap m-auto">
                                    <div className={`${touched && dirty ? 'text-red-500' : ''}`}>+ <Text label={formButton} className="text-md my-auto  font-medium whitespace-nowrap"/></div>
                                </div>
                            </div>
                        </button>
                    }
                    {!isValid && showGeneralErrorOnSubmit &&
                        <Text error right label="bk.form.fix_errors" />
                    }
                    {confirmationalCheckbox &&
                        <div className="flex space-x-2">
                            <Checkbox
                                onChange={() => confirmationalCheckbox.onChange()}
                            />
                            <Text left sm>
                                <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: confirmationalCheckbox.label})}} />
                            </Text>
                        </div>
                    }
                    <div className={'space-x-5 text-right ' + (cancelBtn ? 'float-right inline-flex ' : '')}>
                        {!noSubmitBtn ?
                            <Button type="button"
                                    onClick={submitForm}
                                    icon={submitBtnIcon} label={submitBtnLabel}
                                    small={submitBtnSmall}
                                    // disabled={isSubmitting || !isValid}
                                    disabled={isSubmitting || isFormDisabled}
                                    className={`mr-0 ml-auto ${(submitBtnFullWidth ? 'w-full' : '')} ${(submitBtnClass || '')}`}/>
                            : null
                        }
                        {cancelBtn ?
                            <Button label={cancelBtnLabel} small={submitBtnSmall}
                                    onClick={
                                        (e: any) => {
                                            cancelBtn?.(e);

                                            if (resetInitValuesOnCancel) {
                                                setValues(initValues);
                                            }
                                        }
                                    }
                                    disabled={isSubmitting} secondary={true} />
                            : null
                        }
                    </div>
                </FForm>
            )}
        </Formik>
    );

};
