import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../_actions';
import { Link, Text, LanguageSwitcher, ClientSwitcher, Button } from './';
import { ReactComponent as Logo } from '../assets/img/icons/Logo.svg';
import { ReactComponent as Logout } from '../assets/img/icons/Logout.svg';
import { useAppSelector } from '../_library';
import { useLocation } from 'react-router-dom';

type Props = {};

export const Navbar: (props: Props) => JSX.Element | null = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const user = useAppSelector(state => state.user);
    const [synchronized, setSynchronized] = useState(false);

    const renderNavItem = (link: string, label: string) => (
        <Link
            to={link}
            secondary={true}
            className={
                'underline-offset-[10px] decoration-2 block mt-4 lg:inline-block lg:mt-0 mr-4 last:mr-0 ' +
                (location.pathname.startsWith(link) ? 'underline !text-bk-pink-200' : '')
            }
            label={label}
        />
    );

    useEffect(() => {
        if (user.synchronized) {
            setSynchronized(true);
        }
    }, [user]);

    if (!synchronized) {
        return null;
    }

    return(
        <>
            <nav className="flex items-center justify-between flex-wrap p-8">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <Logo />
                </div>
                <div className="block lg:hidden">
                    <button
                        className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                        </svg>
                    </button>
                </div>
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <div className="text-lg lg:flex-grow">
                        {renderNavItem('/dashboard', 'bk.navbar.dashboard')}
                        {renderNavItem('/transactions', 'bk.navbar.transactions')}
                        {renderNavItem('/users', 'bk.navbar.users')}
                        {renderNavItem('/messaging', 'bk.navbar.messaging')}
                        {user.availableFeatures && ((user.availableFeatures['reporting'] && user.availableFeatures['reporting']['available']) || user.availableFeatures['all_features']) ?
                            <>{renderNavItem('/reporting', 'bk.navbar.reporting')}</>
                          : null
                        }
                        {renderNavItem('/settings', 'bk.navbar.settings')}
                    </div>
                    {/*<div>*/}
                    {/*    <div className="mr-6">*/}
                    {/*        <Text className="inline-block text-base px-4 py-2 leading-none border rounded">*/}
                    {/*            {user.clients.find((c: any) => c.id === user.currentClient)?.name}*/}
                    {/*        </Text>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="mr-3">
                        {user?.provider ?
                            <Button
                              small={true}
                              noShadow={true}
                              staticLabel={user?.provider.toUpperCase()}
                              color='transparent'
                              className="text-base px-2 py-2"
                            /> : null
                        }
                    </div>
                    <div className="mr-3">
                        {user?.topUpAccountIBAN ?
                            <div className="flex items-center">
                                <Button
                                  small={true}
                                  noShadow={true}
                                  staticLabel={user?.topUpAccountIBAN}
                                  color='transparent'
                                  className="text-base px-2 py-2"
                                  onClick={() => {
                                    navigator.clipboard.writeText(user?.topUpAccountIBAN);
                                  }}
                                >
                                    <button 
                                    className="mr-1 px-1 hover:bg-gray-200 rounded-full transition-colors"
                                    title="Copy IBAN to clipboard"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                                        </svg>
                                    </button>
                                </Button>
                            </div> : null
                        }
                    </div>
                    <div>
                        <ClientSwitcher />
                    </div>
                    <div>
                        <LanguageSwitcher/>
                    </div>
                    <div>
                        <div className="flex mt-1 cursor-pointer" onClick={() => (userActions.logout())(dispatch)}>
                            <div className="">
                                <Logout/>
                            </div>
                            <Text className="ml-2" label="bk.dashboard.logout"/>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );

};
